import React, { useState } from 'react';

import NewWindow from '../../assets/new_window.inline.svg';
import GeneralAnchor from '../general-anchor/general-anchor.component';
import RerouteModal from '../reroute-modal/reroute-modal.component';

import LINK_SCHEME from './latest-industry-news.utils';

const LatestIndustryNews = ({ location, links = LINK_SCHEME }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState('');

  const openRerouteModal = (event, url) => {
    event.preventDefault();
    event.stopPropagation();
    setUrl(url);
    setIsModalOpen(true);
  };

  return (
    <section className='latest-industry-news'>
      <h2>Latest industry news</h2>
      <div className='news'>
        <h3>To help you stay up-to-date with developments within our industry:</h3>
        <ul>
          {
            links.map((linkPros, index) => (
              <li key={index}>
                <GeneralAnchor
                  isBlank
                  isLink={true}
                  {...linkPros}
                  onClick={(e) => openRerouteModal(e, linkPros.href)}
                  additionalClass="externalLinks"
                  isTealiumLink={true}
                >
                  <span className='link-title'>{linkPros.title}</span>
                  <NewWindow />
                </GeneralAnchor>
              </li>
            ))
          }
        </ul>
      </div>
      <RerouteModal
        location={location}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        url={url}
      />
    </section>
  );
};

export default LatestIndustryNews;