import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import HsbcNews from '../../components/hsbc-news/hsbc-news.component';
import LatestIndustryNews from '../../components/latest-industry-news/latest-industry-news.component';
import LoadMoreArticles from '../../components/load-more/load-more-articles.component';
import AnchorList from '../../components/anchor-list/anchor-list.component';
import AnnouncementIcon from '../../assets/announcement.inline.png';
import FxBlack from '../../assets/fx-black.inline.png';
import MortgageZone from '../../assets/mortgage-zone.inline.png';
import { MenuContext } from '../../context';
import NewsData2022 from '../../data/newsArticle2022.json';
import NewsData2023 from '../../data/newsArticle2023.json';
import NewsData2024 from '../../data/newsArticle2024.json';

import '../../styles/app.scss';


export default function NewsRoom({ location }) {
  const menuContext = useContext(MenuContext);

  const handleClick = (event) => {
    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.link({page_subcategory: "newsroom"})
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "newsroom"})
    }
  }

  return (
    <Layout
      title='Newsroom'
      metaDescription="Here’s where you can keep up to date with the latest news articles, changes to our products, policy and intermediary service."
    >
      <section className='newsroom-page page-section row'>
        <div className='information-container col-xs-12 col-lg-8'>
          <h2>News</h2>
          <h3 className="subtitle">Latest updates</h3>
          <p className='mb-20'>Here’s where you can keep up to date with the latest news articles, changes to our products, policy <br /> and intermediary service.</p>
          <p className='information'>Please note, the information contained on these pages was correct at the time it was published, however products and rates sometimes <br /> change without notice. Please view the <Link to='/products' inert={menuContext.isMenuOpen ? "true" : null}>product page</Link> for details of our current rates.</p>
          <div className="splitted-section">
            <div className="splitted-section--third">
              <div className='splitted-section__title-section'>
                <img src={FxBlack} alt='Rate change 2023' />
                <h3> Rate changes</h3>
              </div>
              <div className='recent-articles'>
                <p className="information"><strong>2024</strong></p>
                <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2024.rateChange} isTealiumLink={true} />
                {/* <LoadMoreArticles
                  data={NewsData2024.rateChange}
                  initialDataCount={10}
                  dataToShow={10}
                  buttonId='lm0'
                /> */}
              </div>
              <div className='recent-articles'>
                <p className="information"><strong>2023</strong></p>
                <LoadMoreArticles
                  data={NewsData2023.rateChange}
                  initialDataCount={10}
                  dataToShow={10}
                  buttonId='lm0'
                />
              </div>
              <div className='tablet-and-mobile'>
                <p className="information"><strong>2022</strong></p>
                <LoadMoreArticles
                  data={NewsData2022.rateChange}
                  initialDataCount={10}
                  dataToShow={10}
                  buttonId='lm1'
                />
              </div>

            </div>
            <div className="splitted-section--third">
              <div className='splitted-section__title-section'>
                <img src={MortgageZone} alt='Policy updates 2023' />
                <h3>Policy updates</h3>
              </div>
              <div className='recent-articles'>
                <p className="information"><strong>2024</strong></p>
                <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2024.policyUpdates} isTealiumLink={true} isLink={true}/>
              </div>
              <div className='recent-articles'>
                <p className="information"><strong>2023</strong></p>
                <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2023.policyUpdates} isTealiumLink={true}  isLink={true}/>
              </div>
              <div className='tablet-and-mobile margin-bottom-tripple' >
                <p className="information"><strong>2022</strong></p>
                <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2022.policyUpdates} isTealiumLink={true}  isLink={true}/>
              </div>
            </div>
            <div className="splitted-section--third">
              <div className='splitted-section__title-section'>
                <img src={AnnouncementIcon} alt='Industry news 2023' />
                <h3>Industry news</h3>
              </div>
              <div className='recent-articles'>
                <p className="information"><strong>2024</strong></p>
                <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2024.industryNews} isTealiumLink={true} isBlank={true}  isLink={true}/>
              </div>
              <div className='recent-articles'>
                <p className="information"><strong>2023</strong></p>
                <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2023.industryNews} isTealiumLink={true}  isLink={true}/>
              </div>
              <div className='tablet-and-mobile'>
                <p className="information"><strong>2022</strong></p>
                <LoadMoreArticles
                  data={NewsData2022.industryNews}
                  initialDataCount={10}
                  dataToShow={10}
                  buttonId='lm2'
                  isLink={true}
                />
              </div>
            </div>
            <div className="splitted-section--third only-on-desktop">
              <p className="information"><strong>2022</strong></p>
              <LoadMoreArticles
                data={NewsData2022.rateChange}
                initialDataCount={10}
                dataToShow={10}
                buttonId='lm3'
              />
            </div>
            <div className="splitted-section--third only-on-desktop">
              <p className="information"><strong>2022</strong></p>
              <AnchorList additionalClassName={'news-list'} anchorItems={NewsData2022.policyUpdates} isTealiumLink={true} isLink={true}/>
            </div>
            <div className="splitted-section--third only-on-desktop">
              <p className="information"><strong>2022</strong></p>
              <LoadMoreArticles
                data={NewsData2022.industryNews}
                initialDataCount={10}
                dataToShow={10}
                buttonId='lm4'
                isLink={true}
              />
            </div>
          </div>
          <LatestIndustryNews location={location} />
          <p className='information usage first'>
            This website is for the use of FCA authorised mortgage intermediaries only.
          </p>
          <p className='information usage'>
            If you reproduce any information contained in this website, to be used with or advise clients, you must ensure it follows the FCA's advising and selling standards.</p>
        </div>
        <div className='support-container col-xs-12 col-lg-4'>
          <HsbcNews />
          <p className='information usage first'>
            This website is for the use of FCA authorised mortgage intermediaries only.
          </p>
          <p className='information usage'>
            If you reproduce any information contained in this website, to be used with or advise clients, you must ensure it follows the FCA's advising and selling standards.
          </p>
        </div>
      </section>
    </Layout >
  );
}